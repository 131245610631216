<template>
  <div class="edit-event-management content">
    <div class="content__navbar">
      <div class="content__navbar-top">
        <div @click="$router.go(-1)" class="back">
          <img src="../../../assets/svg/arrows/back.svg" alt="">
          <p>{{ $t('back') }}</p>
        </div>
        <p class="link">{{ $t('change-event') }}</p>
      </div>
      <p class="content__navbar-bottom" @click="visibleAlert = true">{{ $t('delete-event') }}</p>
    </div>
    <div class="content__form">
      <img class="content__form-image" :src="form?.image?.url || require('@/assets/images/event-card.png')" alt="" />
      <label class="content__form-wrap" @input="updateAvatar" :style="{background: form.image ? 'transparent' : '#D9D9D9'}">
        <input type="file" ref="avatar">
        <img src="../../../assets/svg/icon-camera-white.svg" alt="" />
      </label>
      <ui-input
          :label="$t('title')"
          v-model="form.name"
          required-field
          :error="$v.form.name.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-date-picker
          :label="$t('date')"
          v-model="form.startDate"
          required-field
          :error="$v.form.startDate.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-input
          v-mask="'##:##'"
          :label="$t('time')"
          v-model="form.startTime"
          required-field
          :error="$v.form.startTime.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-input
          :label="$t('organizer')"
          v-model="form.organizer"
          required-field
          :error="$v.form.organizer.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-input
          :label="$t('venue')"
          v-model="form.location"
          required-field
          :error="$v.form.location.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-input
          :label="$t('for-whom')"
          v-model="form.forWhom"
          required-field
          :error="$v.form.forWhom.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-input
          v-mask="'#################'"
          :label="$t('participants')"
          v-model="form.participants"
          required-field
          :error="$v.form.participants.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-textarea
          :label="$t('description')"
          v-model="form.description"
          radius="10"
          height="88"
          required-field
          :error="$v.form.description.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-button @click="submit" :disabled="$v.form.$anyError || shouldDisabled">{{ $t('save') }}</ui-button>
    </div>
    <p class="content__bottom" @click="visibleAlert = true">{{ $t('delete-event') }}</p>
    <ui-alert v-model="visibleAlert">
      <div class="ui-alert__wrap">
        <div class="ui-alert__title">{{ $t('do-you-really-want-to-delete-event') }}</div>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="deleteItem(); closeAlert();" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="visibleAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import UiInput from "@/components/ui/UiInput.vue";
import UiDatePicker from "@/components/ui/UiDatePicker.vue";
import UiTextarea from "@/components/ui/UiTextarea.vue";
import UiButton from "@/components/ui/UiButton.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getDate, getFullDate} from "../../../utils/general";
import UiAlert from "@/components/ui/UiAlert.vue";
import {minLength, required} from "vuelidate/lib/validators";

export default {
  name: "EditEventManagement",
  components: {UiAlert, UiButton, UiTextarea, UiDatePicker, UiInput},
  data() {
    return {
      form: {
        name: '',
        organizer: '',
        location: '',
        forWhom: '',
        description: '',
        image: {},
        startDate: null,
        startTime: '',
        participants: null
      },
      visibleAlert: false
    }
  },

  validations() {
    return {
      form: this.getRules
    }
  },

  computed: {
    ...mapGetters(['getEventsId']),

    shouldDisabled() {
      let empty = this.getFields.find(prop => !this.form[prop])
      return empty !== undefined
    },

    fieldEdit() {
      return ['name', 'organizer', 'location', 'startDate', 'startTime', 'forWhom', 'description', 'image', 'participants']
    },

    requiredRules() {
      return {
        name: {
          required,
        },
        startDate: {
          required,
        },
        startTime: {
          required,
          minLength: minLength(5)
        },
        organizer: {
          required,
        },
        location: {
          required,
        },
        forWhom: {
          required,
        },
        description: {
          required,
        },
        image: {
          required,
        },
        participants: {
          required
        }
      }
    },

    getRules() {
      return this.requiredRules
    },

    getFields() {
      return this.fieldEdit
    },
  },

  methods: {
    getDate,
    getFullDate,
    ...mapActions(['reqUploadAvatar', 'eventsId', 'editEvent', 'deleteEvent']),
    ...mapMutations(['setEvents']),
    updateAvatar({ target }) {
      this.reqUploadAvatar(target.files[0]).then((res) => {
        this.form.image = res
        console.log(res)
      })
    },

    collectForm () {
      this.form = {
        name: this.getEventsId.name,
        organizer: this.getEventsId.organizer,
        location: this.getEventsId.place,
        forWhom: this.getEventsId.target,
        description: this.getEventsId.description,
        startDate: new Date(this.getEventsId.startDate),
        startTime: this.getEventsId.startTime ? this.getEventsId.startTime.slice(0,5) : '',
        image: {
          filename: this.getEventsId?.imageSrc.file,
          url: this.getEventsId?.imageSrc?.fileSrc,
        },
        participants: this.getEventsId.participantCnt ? this.getEventsId.participantCnt.toString() : ''
      }
    },

    submit () {
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        const payload = {
          name: this.form.name,
          organizer: this.form.organizer,
          place: this.form.location,
          target: this.form.forWhom,
          description: this.form.description,
          image: this.form.image.filename || null,
          startDate: this.getDate(this.form.startDate),
          startTime: this.form.startTime,
          // endDate: this.getDate(this.getEventsId.endDate),
          // endTime: this.getEventsId.endTime,
          participantCnt: +this.form.participants,
          id: this.getEventsId.id
        }

        this.editEvent(payload).then(() => {
          this.$router.push(`/event-management/${this.$route.params.id}`)
        })
      }
    },

    callDeleteApi () {
      this.deleteEvent({id: this.$route.params.id}).then(() => {
        this.setEvents([])
        this.$router.push('/event-management')
      })
    },

    closeAlert () {
      this.visibleAlert = false;
    },

    deleteItem () {
      this.callDeleteApi()
      this.closeAlert()
    }
  },

  mounted () {
    this.eventsId({id: this.$route.params.id}).then(() => {
      this.collectForm()
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  gap: 270px;
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  min-height: 100vh;

  @media (max-width: 1000px) {
    gap: 20px;
    padding: 104px 20px 50px 20px;
  }

  @media (max-width: 786px) {
    flex-direction: column;
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-top {
      display: flex;
      flex-direction: column;
      gap: 51px;

      @media (max-width: 786px) {
        gap: 20px;
      }

      .back {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }

      .link {
        color: #CE2121;
        font-size: 16px;
      }
    }

    &-bottom {
      color: #9A9A9A;
      font-size: 16px;
      cursor: pointer;

      @media (max-width: 786px) {
        display: none;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 434px;
    width: 100%;
    padding: 30px 34px;
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: 2px 2px 10px 0px rgba(153, 153, 153, 0.20);
    position: relative;

    &-image {
      width: 100%;
      height: 193px;
      border-radius: 10px;
      margin: 0 auto;
      object-fit: cover;
    }

    &-wrap {
      max-width: 366px;
      width: 100%;
      height: 193px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 10px;
      margin: 0 auto;
      position: absolute;

      img {
        width: 44px;
        height: 37px;
      }

      input {
        display: none;
      }
    }
  }

  &__bottom {
    display: none;
    @media (max-width: 768px) {
      color: #9A9A9A;
      font-size: 16px;
      display: inline-block;
      cursor: pointer;
    }
  }
}
</style>
